import { BeLocationStep, CzLocationStep } from 'components/Onboarding/LocationStep';
import {
  BelgiumOnboardingValues,
  CzechOnboardingValues,
  OnboardingValues,
} from 'components/Onboarding/types';
import { isEqual, merge, omit, pick } from 'lodash';
import { CountryCode } from 'types/config';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from 'utils/localStorageManager';

export const getInitialValues = (
  isoCode: CountryCode,
  activity: string = '',
  products: string[] = [],
) => {
  switch (isoCode) {
    case 'cz':
      return {
        country: 'cz',
        ...CzLocationStep.initial(),
        activities: [{ activity, subActivity: '', terActivity: '' }],
      } as CzechOnboardingValues;
    case 'be':
      return {
        country: 'be',
        ...BeLocationStep.initial(),
        products,
      } as BelgiumOnboardingValues;
    default:
      throw 'No initial values exist for this country';
  }
};

const getKey = (contractId?: string) => `onboarding-${contractId}`;

export const discardOnboarding = (contractId: string) => {
  removeLocalStorage(getKey(contractId));
};

export const storeOnboarding = (contractId: string, changes: OnboardingValues) => {
  setLocalStorage(getKey(contractId), changes);
};

/**
 * Calculates initial onboarding values
 *
 * @param isoCode Current country
 * @param contractId Contract identifier used to retrieve data from local storage
 * @param activity Activity to be pre-set on generating initial values
 * @param products Products to be pre-set on generating initial values
 */
export const getInitialOnboardingValues = (
  isoCode: CountryCode,
  contractId: string,
  activity: string = '',
  products: string[] = [],
) => {
  const initialValues = getInitialValues(isoCode, activity, products);
  const storedValues = getLocalStorage(getKey(contractId), {});

  const pickedValues = pick(storedValues, Object.keys(initialValues));
  return merge({}, initialValues, pickedValues);
};

export const hasModifiedOnboarding = (contractId: string, isoCode: CountryCode) => {
  try {
    const initialValues = getInitialValues(isoCode, '', []);
    const storedValues = getLocalStorage(getKey(contractId), {});
    const maybeValues = merge({}, initialValues, pick(storedValues, Object.keys(initialValues)));

    const toOmit = ['type', 'products', 'activities'];
    const maybeValuesOmitted = omit(maybeValues, toOmit);
    const initValuesOmitted = omit(initialValues, toOmit);

    return !isEqual(initValuesOmitted, maybeValuesOmitted);
  } catch (e) {
    return false;
  }
};
