import { addMonths, startOfToday } from 'date-fns';
import { LocationTypeEnum, PaymentType } from 'generated/models';
import { IsEnabledFn, PaymentTypeDict, TerminalProviders, } from './types';

export const TERMINAL_DELAY_START = startOfToday();
export const TERMINAL_DELAY_END = addMonths(TERMINAL_DELAY_START, 6);

export const isPaymentEnabled = (types?: PaymentType[]): IsEnabledFn => {
  const states = (types ?? []).reduce((result, paymentType) => {
    paymentType.products.forEach((product) => {
      if (product.forTypes?.includes(LocationTypeEnum.Online)) {
        result[`${paymentType.code}.${product.productCode}.${LocationTypeEnum.Online}`] = true;
        result[`${null}.${product.productCode}.${LocationTypeEnum.Online}`] = true;
        result[`${null}.${null}.${LocationTypeEnum.Online}`] = true;
      }
      if (product.forTypes?.includes(LocationTypeEnum.Physical)) {
        result[`${paymentType.code}.${product.productCode}.${LocationTypeEnum.Physical}`] = true;
        result[`${null}.${product.productCode}.${LocationTypeEnum.Physical}`] = true;
        result[`${null}.${null}.${LocationTypeEnum.Physical}`] = true;
      }
      if (product.forTypes?.includes(LocationTypeEnum.Marketplace)) {
        result[`${paymentType.code}.${product.productCode}.${LocationTypeEnum.Marketplace}`] = true;
        result[`${null}.${product.productCode}.${LocationTypeEnum.Marketplace}`] = true;
        result[`${null}.${null}.${LocationTypeEnum.Marketplace}`] = true;
      }
    });

    return result;
  }, {} as PaymentTypeDict);

  return (paymentTypeCode, productCodes, locationType) => {
    if (productCodes === null) {
      return `${paymentTypeCode}.${productCodes}.${locationType}` in states;
    }
    return productCodes.some(
      (productCode) => `${paymentTypeCode}.${productCode}.${locationType}` in states,
    );
  };
};

export const terminalProviderTIDs = (items: Record<string, any>[]): TerminalProviders => {
  const providers: Array<{ value: string; label: string }> = [];
  const tids: Record<string, string> = {};

  items.forEach((item) => {
    if (item?.provider?.name) {
      providers.push({
        value: item?.provider?.name as string,
        label: item?.provider?.name as string,
      });
      (item?.provider?.pretids?.pretid || [])?.forEach((pretid: string) => {
        tids[pretid] = item?.provider?.name;
      });
    }
  });

  return { providers, tids };
};

export const findTerminalProviderByTID = (
  terminalId: string,
  tids: Record<string, string>,
  maxLength = 3,
): string | '' => {
  const chars = terminalId.split('');
  let word = '';

  for (const char of chars) {
    word += char;

    if (word in tids) {
      return tids[word];
    }

    if (word.length > maxLength) {
      return '';
    }
  }

  return '';
};
