import { VStack } from '@chakra-ui/react';
import { Button, FieldHelpText, InputVariant } from '@pluxee-design-system/core';
import TerminalRow from 'components/Onboarding/LocationStep/BeTerminalRow';
import { BelgiumOnboardingValues, POSValues, TerminalProviders } from 'components/Onboarding/types';
import { FieldArray, useFormikContext } from 'formik';
import { I18nProps, withTranslations } from 'i18n';
import { isEqual } from 'lodash';
import gtm from 'trackers/gtm';
import { ConfigField } from 'types/config';

interface TerminalsProps extends I18nProps {
  config: Record<string, ConfigField>;
  disabled?: boolean;
  isOnboarding?: boolean;
  terminalProviders: TerminalProviders;
}

const template = (id: string): POSValues => ({
  country: 'be',
  id,
  terminalId: '',
  terminalProvider: '',
});

const Terminals = ({ config, disabled, isOnboarding, terminalProviders, t }: TerminalsProps) => {
  const { errors, initialValues, setFieldValue, touched, values } =
    useFormikContext<BelgiumOnboardingValues>();
  const isTouched = Boolean(touched.POS || !isEqual(values.POS, initialValues.POS));

  return (
    <FieldArray
      name="POS"
      render={(arrayHelpers) => (
        <VStack align="stretch" spacing={4}>
          <VStack align="stretch" spacing={6}>
            {values.POS.map((value, index) => (
              <TerminalRow
                key={`POS-field-${index}`}
                config={config}
                idPrefix=""
                name={`POS[${index}].`}
                index={index}
                onProvider={setFieldValue}
                onRemove={arrayHelpers.remove}
                providers={terminalProviders}
                value={value}
              />
            ))}
          </VStack>
          <Button
            type="button"
            onClick={() => {
              if (isOnboarding) gtm.trackClickLocationAddTerminal();
              arrayHelpers.push(template(values.POS.length.toString()));
            }}
            variant="secondaryOutlined"
            id="Terminal_addTerminal"
            size="md"
            alignSelf="flex-start"
          >
            {t('location_add.buttons.addTerminal', 'Add a terminal')}
          </Button>

          {isTouched && errors.POS && (
            <FieldHelpText
              disabled={disabled}
              text={errors.POS as string}
              variant={InputVariant.ERROR}
            />
          )}
        </VStack>
      )}
    />
  );
};

export default withTranslations(Terminals);
