import { VStack } from '@chakra-ui/react';
import { Button } from '@pluxee-design-system/core';
import {
  AddressControl,
  CheckboxControl,
  CheckboxGroupControl,
  InputControl,
  PhoneNumberControl,
  SubmitButton,
} from 'common/forms';
import { SectionTitle } from 'common/typography';
import { uniqProductCodesFromActivities } from 'components/Form/ActivityTree/utils';
import ActivityTreeGroupControl from 'components/Form/ActivityTreeGroupControl';
import CzProductActivityInfo from 'components/Onboarding/LocationStep/CzProductActivityInfo';
import { activityProductImpactModalParams } from 'components/Onboarding/modals';
import POSStep from 'components/Onboarding/POSStep';
import ProductWithLogo from 'components/Onboarding/POSStep/ProductWithLogo';
import { ProductCheckboxItem } from 'components/Products/ProductCheckbox';
import { createArraySchema, createSchema, getValidationErrors, posArrayAsync } from 'config';
import useModal from 'context/ModalContext';
import useDeepMemo from 'data/useDeepMemo';
import { useFormikContext } from 'formik';
import { ErrorResponse, ProductStatusEnum } from 'generated/models';
import { withTranslations } from 'i18n';
import { useCallback, useMemo } from 'react';
import gtm from 'trackers/gtm';
import { CzechLocationConfig } from 'types/config';
import { CzechOnboardingValues } from '../types';
import { LocationStepType } from './types';

const callCache: Record<string, ErrorResponse | null> = {};

const LocationStep: LocationStepType<CzechLocationConfig> = ({
  activities = [],
  config: { form: config },
  isOnboarding = true,
  isSkippable,
  contractProducts = [],
  steps,
  toStep,
  t,
}) => {
  const showModal = useModal();
  const { setFieldValue, values } = useFormikContext<CzechOnboardingValues>();
  const selectedActivities = useMemo(
    () => values.activities.map((activity) => activity.terActivity),
    [values.activities],
  );
  const allowedProductCodesByActivities = useDeepMemo(
    () => uniqProductCodesFromActivities(activities, selectedActivities),
    [activities, selectedActivities],
  );

  const products = useMemo(
    () =>
      contractProducts.map<ProductCheckboxItem>(
        ({ payout, product, product: { code, name, nameKey }, status }) => {
          const isAllowed =
            allowedProductCodesByActivities.length > 0 &&
            allowedProductCodesByActivities.includes(code);
          return {
            value: code,
            isAllowed,
            isDisabled:
              !isAllowed ||
              (isOnboarding ? false : status !== undefined && status !== ProductStatusEnum.Active),
            label: t('product_details.products.*.product.name', name, {
              query: { where: 'product_details.products.*.product.code', is: nameKey },
            }),
            nameKey,
            payout,
            product,
            status: isOnboarding ? undefined : status,
          };
        },
      ),
    [allowedProductCodesByActivities, isOnboarding, contractProducts, t],
  );
  const handleActivityChange = useCallback(
    async (index: number, terActivity: string) => {
      const newSelectedActivities = [...selectedActivities];
      newSelectedActivities[index] = terActivity;
      const newAllowedProductCodesByActivities = uniqProductCodesFromActivities(
        activities,
        newSelectedActivities,
      );
      const impactedProducts = values.products.filter(
        (productCode) => !newAllowedProductCodesByActivities.includes(productCode),
      );
      const impactedProductNames = impactedProducts.map(
        (impactedProduct) => products.find((product) => product.value === impactedProduct)?.label!,
      );

      let isChangeAllowed = true;
      if (
        impactedProducts.length > 0 &&
        (isChangeAllowed =
          (await showModal(activityProductImpactModalParams(t, impactedProductNames))) === true)
      ) {
        // remove impacted products
        setFieldValue(
          'products',
          values.products.filter((productCode) => !impactedProducts.includes(productCode)),
        );
      }
      return Promise.resolve(isChangeAllowed);
    },
    [selectedActivities, activities, values.products, products, showModal, t, setFieldValue],
  );

  const trackSubmit = useCallback(() => {
    const activityDict = activities?.reduce(
      (result, curr) => {
        result[curr.code] = curr.name;
        return result;
      },
      {} as Record<string, string>,
    );

    gtm.trackClickLocationConfirm({
      event_location_name: values.name,
      event_activity: values.activities.reduce((result, curr) => {
        const activity = activityDict?.[curr.activity];
        const subActivity = activityDict[curr.subActivity];
        const terActivity = activityDict[curr.terActivity];

        if (activity) {
          const resultActivities = [activity];
          if (subActivity) resultActivities.push(subActivity);
          if (terActivity) resultActivities.push(terActivity);
          result.push(resultActivities);
        }
        return result;
      }, [] as string[][]),
      event_accepted_product: values.products.reduce((result, curr) => {
        const productName = products.find((p) => p.value === curr)?.label;
        if (productName) result.push(productName);
        return result;
      }, [] as string[]),
      event_terminal_definition: values.POS.map((pos) => ({
        deviceType: t(`location_add.form.deviceTypeOptions.${pos.deviceType}`, pos.deviceType),
        products: pos.productIds.reduce((result, curr) => {
          const productName = products.find((p) => p.value === curr)?.label;
          if (productName) result.push(productName);
          return result;
        }, [] as string[]),
      })),
    });
  }, [activities, values.name, values.activities, values.products, values.POS, products, t]);

  return (
    <>
      <VStack align="stretch" spacing={4}>
        <SectionTitle
          title={t('location_add.sectionTexts.addLocation.header', 'Add business locations')}
          description={t(
            'location_add.sectionTexts.addLocation.subheader',
            'Describe your business to your customers',
          )}
        />
        {config.locationName.visible && (
          <InputControl
            type="text"
            mandatory={config.locationName.mandatory}
            name="name"
            id="LocationStep_name"
            placeholder={t('location_add.form.locationName.label', 'Location name')}
            title={t('location_add.form.locationName.label', 'Location name')}
          />
        )}
        <AddressControl config={config} idPrefix="LocationStep" tPrefix="location_add.form" />
        {config.website.visible && (
          <InputControl
            type="text"
            mandatory={config.website.mandatory}
            name="webSiteUrl"
            id="LocationStep_webSiteUrl"
            placeholder={t('location_add.form.website.label', 'Web sites link')}
            title={t('location_add.form.website.label', 'Web sites link')}
          />
        )}
        {config.email.visible && (
          <InputControl
            type="email"
            mandatory={config.email.mandatory}
            name="email"
            id="LocationStep_email"
            placeholder={t('location_add.form.email.label', 'Email')}
            title={t('location_add.form.email.label', 'Email')}
          />
        )}
        {config.phoneNumber.visible && (
          <PhoneNumberControl
            mandatory={config.phoneNumber.mandatory}
            name="phoneNumber"
            id="LocationStep_phoneNumber"
            title={t('location_add.form.phoneNumber.label', 'Primary phone')}
          />
        )}
        {config.secondaryPhoneNumber.visible && (
          <PhoneNumberControl
            mandatory={config.secondaryPhoneNumber.mandatory}
            name="secondaryPhoneNumber"
            id="LocationStep_secondaryPhoneNumber"
            title={t('location_add.form.secondaryPhoneNumber.label', 'Secondary phone')}
          />
        )}
        {config.merchantLocationId.visible && (
          <InputControl
            type="text"
            mandatory={config.merchantLocationId.mandatory}
            name="merchantLocationId"
            id="LocationStep_merchantLocationId"
            placeholder={t('location_add.form.merchantLocationId.label', 'Merchant location ID')}
            title={t('location_add.form.merchantLocationId.label', 'Merchant location ID')}
          />
        )}
        {config.wifiAvailable.visible && (
          <CheckboxControl
            mandatory={config.wifiAvailable.mandatory}
            name="wifi"
            id="LocationStep_wifi"
          >
            {t('location_add.form.wifiAvailable', 'Wifi available')}
          </CheckboxControl>
        )}
      </VStack>
      {config.typeOfActivity.visible && (
        <VStack align="stretch" spacing={4}>
          <SectionTitle
            title={t(
              'location_add.sectionTexts.addLocation.selectLocationActivities',
              'Select location activities',
            )}
          />
          <ActivityTreeGroupControl
            activities={activities}
            name="activities"
            isChangeAllowed={handleActivityChange}
            isFirstChangeable
          />
        </VStack>
      )}
      {config.products.visible && (
        <VStack align="stretch" spacing={4}>
          <SectionTitle
            title={t(
              'location_add.sectionTexts.addLocation.acceptedProducts',
              'Accepted Pluxee products',
            )}
            description={t(
              'location_add.sectionTexts.addLocation.acceptedProductsSubheader',
              'Product selection is limited by selected location activities',
            )}
          />
          <CheckboxGroupControl
            mandatory={config.products.mandatory}
            name="products"
            id="CompanyInfoStep_products"
            options={products}
            renderLeftElement={ProductWithLogo}
            renderRightElement={CzProductActivityInfo}
          />
        </VStack>
      )}
      <VStack align="stretch" spacing={4}>
        <SectionTitle
          title={t('location_add.sectionTexts.addTerminal.header', 'Add POS terminals')}
          description={t(
            'location_add.sectionTexts.addTerminal.subheader',
            'Or at least of those, that will be accepting Pluxee products.',
          )}
        />
        <POSStep
          config={config.terminals}
          contractProducts={products}
          isOnboarding={isOnboarding}
        />
      </VStack>
      <VStack align="stretch" spacing={4}>
        <SubmitButton
          variant="primaryFilled"
          id="LocationStep__doneButton"
          onClick={isOnboarding ? trackSubmit : undefined}
          width="100%"
        >
          {t('global_texts.buttons.portalDone', 'Done')}
        </SubmitButton>
        {isSkippable && (
          <Button
            variant="primaryOutlined"
            id="LocationStep__skipButton"
            type="reset"
            onClick={() => toStep(steps.length)}
            width="100%"
            background="semantic.surface.1"
          >
            {t('global_texts.buttons.skip', 'Skip')}
          </Button>
        )}
      </VStack>
    </>
  );
};

LocationStep.initial = () => ({
  name: '',
  streetName: '',
  houseNumber: '',
  city: '',
  postalCode: '',
  webSiteUrl: '',
  email: '',
  phoneNumber: '',
  secondaryPhoneNumber: '',
  wifi: false,
  activities: [],
  products: [],
  merchantLocationId: '',
  POS: [],
});

LocationStep.schema = ({ config }) =>
  createSchema({
    name: config.form.locationName,
    streetName: config.form.streetName,
    houseNumber: config.form.houseNumber,
    city: config.form.city,
    postalCode: config.form.postalCode,
    webSiteUrl: config.form.website,
    email: config.form.email,
    phoneNumber: config.form.phoneNumber,
    secondaryPhoneNumber: config.form.secondaryPhoneNumber,
    wifi: config.form.wifiAvailable,
    activities: config.form.typeOfActivity,
    products: config.form.products,
    merchantLocationId: config.form.merchantLocationId,
  }).concat(
    createArraySchema('POS', {
      deviceType: config.form.terminals.deviceType,
      terminalId: config.form.terminals.terminalId,
      email: config.form.terminals.email,
      productIds: config.form.terminals.products,
    }),
  );

LocationStep.validateSubmit = async (values) => {
  const errors: Record<string, any> = {};
  try {
    const asyncSchema = posArrayAsync('POS');
    await asyncSchema.validate(values, { context: callCache, abortEarly: false });
  } catch (error) {
    // abortEarly = false, must access inner
    getValidationErrors(error.inner, errors);
  }

  return errors;
};

export default withTranslations(LocationStep) as LocationStepType<CzechLocationConfig>;
