import { Grid, GridItem } from '@chakra-ui/react';
import {
  Button,
  Icons,
  Notification,
  PassiveVariants,
  SvgSpriteConsumer,
} from '@pluxee-design-system/core';
import { InputControl, SelectControl } from 'common/forms';
import { useFormikContext } from 'formik';
import { I18nProps, withTranslations } from 'i18n';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { ConfigField } from 'types/config';
import { BelgiumOnboardingValues, BelgiumPOSValues, TerminalProviders } from '../types';
import { findTerminalProviderByTID } from '../utils';

interface TerminalRowProps extends I18nProps {
  config: Record<string, ConfigField>;
  idPrefix: string;
  index: number;
  name: string;
  onProvider: (fieldName: string, value: string, validate: boolean) => void;
  onRemove: (index: number) => void;
  providers: TerminalProviders;
  value: BelgiumPOSValues;
}

const TerminalRow = ({
  config,
  idPrefix,
  index,
  name,
  onProvider,
  onRemove,
  providers: { providers, tids },
  value,
  t,
}: TerminalRowProps) => {
  const { errors } = useFormikContext<BelgiumOnboardingValues>();
  const handleRemove = useCallback(() => onRemove(index), [index, onRemove]);
  const handleProvider = useMemo(
    () => debounce((value: string) => onProvider(`${name}terminalProvider`, value, true), 200),
    [name, onProvider],
  );
  // @ts-expect-error
  const hasTerminalIdError = Boolean(errors[`POS[${index}].terminalId`]);
  const columnsSize = config.terminalId.visible ? (config.terminalProvider.visible ? 2 : 1) : 0;
  const showTerminalIdNotification = useMemo(
    () => !hasTerminalIdError && !Boolean(findTerminalProviderByTID(value.terminalId, tids)),
    [hasTerminalIdError, tids, value.terminalId],
  );

  useEffect(() => {
    handleProvider(findTerminalProviderByTID(value.terminalId, tids));
  }, [handleProvider, tids, value.terminalId]);

  return (
    <Grid
      key={`POS-field-${index}`}
      templateAreas={{
        base: `"input button"
        "select button"`,
        md: `"input select button"`,
      }}
      templateColumns={{ base: `1fr 70px`, md: `repeat(${columnsSize}, 1fr) 70px` }}
      gap={4}
    >
      {config.terminalId.visible && (
        <GridItem width="100%" area="input">
          <InputControl
            type="text"
            mandatory
            name={`${name}terminalId`}
            id={`${idPrefix}${name}terminalId`}
            placeholder={t('location_add.form.terminalId.label', 'Terminal ID')}
            title={t('location_add.form.terminalId.label', 'Terminal ID')}
          />
        </GridItem>
      )}

      {config.terminalProvider.visible && (
        <GridItem width="100%" area="select">
          <SelectControl
            mandatory
            disabled={!showTerminalIdNotification}
            name={`${name}terminalProvider`}
            id={`${idPrefix}${name}terminalProvider`}
            placeholder={t('location_add.form.terminalProviderOnline.label', 'Provider')}
            title={t('location_add.form.terminalProviderOnline.label', 'Provider')}
            notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
            options={providers}
          />
        </GridItem>
      )}
      <GridItem width="70px" mt={7} area="button">
        <Button
          type="button"
          onClick={handleRemove}
          variant="secondaryTextOnly"
          id={`${idPrefix}${name}deleteButton`}
          size="md"
          alignSelf="flex-end"
        >
          <SvgSpriteConsumer size={24} svgId={Icons.TRASH24} />
        </Button>
      </GridItem>
      {showTerminalIdNotification && (
        <GridItem colStart={1} colEnd={{ base: 2, md: columnsSize + 1 }}>
          <Notification
            description={t('location_add.form.terminalId.informativeMessage', '12323')}
            variant={PassiveVariants.PASSIVE_INFORMATION}
            isClosable={false}
            withoutShadow
          />
        </GridItem>
      )}
    </Grid>
  );
};

export default withTranslations(TerminalRow);
