import { Button } from '@pluxee-design-system/core';
import { ProductCheckboxItem } from 'components/Products/ProductCheckbox';
import { FieldArray, useFormikContext } from 'formik';
import { PosTypeEnum, ProductTypeEnum } from 'generated/models';
import { I18nProps, withTranslations } from 'i18n';
import { useMemo } from 'react';
import gtm from 'trackers/gtm';
import { TerminalConfig } from 'types/config';
import { CzechOnboardingValues, POSValues } from '../types';
import POSRow from './POSRow';

interface POSStepProps extends I18nProps {
  config: TerminalConfig;
  contractProducts: ProductCheckboxItem[];
  isOnboarding?: boolean;
}

const template = (id: string): POSValues => ({
  country: 'cz',
  id,
  bankId: '',
  deviceType: PosTypeEnum.Pos,
  productIds: [],
  terminalId: '',
  terminalCode: '',
  masterId: '',
  email: '',
  certificateEmail: '',
});

const POSStep = ({ config, contractProducts = [], isOnboarding = true, t }: POSStepProps) => {
  const { errors, values } = useFormikContext<CzechOnboardingValues>();
  const products = useMemo(
    () =>
      contractProducts.filter(
        ({ product }) =>
          product.type !== ProductTypeEnum.Paper && values.products.includes(product.code),
      ),
    [contractProducts, values.products],
  );
  const hasPOS = values.POS.length > 0;
  const isValid =
    values.POS.length === 0 || (values.POS.length > 0 && (errors.POS?.length || 0) === 0);

  return (
    <FieldArray
      name="POS"
      render={(arrayHelpers) => {
        const addButton = (
          <Button
            isDisabled={!isValid}
            type="button"
            onClick={() => {
              if (isOnboarding) gtm.trackClickLocationAddTerminal();
              arrayHelpers.push(template(values.POS.length.toString()));
            }}
            variant="secondaryOutlined"
            id="POSStep_posFormSubmitButton"
            size="md"
            alignSelf="flex-start"
          >
            {t('location_add.buttons.addTerminal', 'Add an other terminal')}
          </Button>
        );

        return (
          <>
            {values.POS &&
              values.POS.map((_, index) => (
                <POSRow
                  key={`POS-field-${index}`}
                  config={config}
                  idPrefix="POSStep_"
                  name={`POS[${index}].`}
                  index={index}
                  isMultiProductsAllowed={false}
                  isOnboarding={isOnboarding}
                  onRemove={() => arrayHelpers.remove(index)}
                  products={products}
                  showDivider={index + 1 < values.POS.length}
                >
                  {hasPOS && index === values.POS.length - 1 && addButton}
                </POSRow>
              ))}
            {!hasPOS && addButton}
          </>
        );
      }}
    />
  );
};

export default withTranslations(POSStep);
