import { SimpleGrid } from '@chakra-ui/react';
import { InputControl, SelectControl } from 'common/forms';
import { mapGroupToKey } from 'components/Form';
import { I18nProps, withTranslations } from 'i18n';
import { useMemo } from 'react';
import { ConfigField } from 'types/config';

interface OnlineTerminalProps extends I18nProps {
  config: Record<string, ConfigField>;
  disabled?: boolean;
}

const OnlineTerminal = ({ config, disabled, t, tGroup }: OnlineTerminalProps) => {
  const terminalProviders = useMemo(
    () => mapGroupToKey(tGroup('terminal_providers_online.terminalProviders')),
    [tGroup],
  );

  return (
    <>
      {(config.terminalProviderOnline.visible || config.terminalIdOnline.visible) && (
        <SimpleGrid
          columns={{
            base: 1,
            md: config.terminalProviderOnline.visible && config.terminalIdOnline.visible ? 2 : 1,
          }}
          spacing={4}
        >
          {config.terminalIdOnline.visible && (
            <InputControl
              type="text"
              disabled={disabled}
              mandatory={config.terminalIdOnline.mandatory}
              name="terminalIdOnline"
              id="LocationStep_terminalIdOnline"
              placeholder={t('location_add.form.terminalIdOnline.label', 'Supplier ID')}
              title={t('location_add.form.terminalIdOnline.label', 'Supplier ID')}
            />
          )}
          {config.terminalProviderOnline.visible && (
            <SelectControl
              disabled={disabled}
              mandatory={config.terminalProviderOnline.mandatory}
              name="terminalProviderOnline"
              id="LocationStep_terminalProviderOnline"
              placeholder={t('location_add.form.terminalProviderOnline.label', 'Provider')}
              title={t('location_add.form.terminalProviderOnline.label', 'Provider')}
              notFoundText={t('global_texts.labels.noResults', 'No results matching a query')}
              options={terminalProviders}
            />
          )}
        </SimpleGrid>
      )}
    </>
  );
};

export default withTranslations(OnlineTerminal);
