import { Box } from '@chakra-ui/react';
import { SelectOption, Text } from '@pluxee-design-system/core';
import { ReactNode } from 'react';
import { PaymentOptionEnum } from '../types';

export interface PaymentOptionItem extends SelectOption {
  value: PaymentOptionEnum;
  description: string;
  content?: string | ReactNode;
}

const PaymentOption = ({ content, description, label }: PaymentOptionItem) => (
  <Box width="100%">
    <Text variant="subtitle.section">{label}</Text>
    <Text variant="body.medium" mt={1}>
      {description}
    </Text>
    {content && <Box mt={4}>{content}</Box>}
  </Box>
);

export default PaymentOption;
